(function($, window, document, undefined) {
    var $doc = $(document);

	$('.link-popup').magnificPopup({
		closeOnContentClick: false,
		closeOnBgClick: false,
		callbacks: {
			close: function() {
				if ( $( '.gform_confirmation_message' ).length ) {
					window.location.href = crb.home_url;
				}
			}
		}
	});

    $( '.variations_form' ).on( 'woocommerce_variation_select_change', function () {
        if( $( '.swatch-wrapper.selected' ).length ) {
            $( '.swatch-notice-custom' ).remove();
        }
    } );

    $doc.on( 'click', '.single-product .add-request-quote-button', function() {
        var $container = $( '.yith-ywraq-add-to-quote' );
        var isDisabled = $( this ).hasClass( 'disabled' );

        if ( isDisabled && $( '.swatch-notice-custom' ).length === 0 ) {
            var $message = $( '<div>' ).addClass( 'swatch-notice-custom' ).text( 'You need to select a fabric swatch!' );
            $container.prepend( $message );
        }
    } );

	$( '.cart .select.qty' ).on( 'change', function() {
		if( typeof request !== 'undefined' ){
            request.abort();
        }

        var $t = $(this),
            totals =  $t.closest('table').find('.raq-totals'),
            total_inline = $t.closest('tr').find('.product-subtotal'),
            name = $t.attr('name');

        if( typeof name ==   'undefined'){
            var $input_quantity = $t.closest('.product-quantity').find('.input-text.qty'),
                name = $input_quantity.attr('name'),
                value = $input_quantity.val(),
                item_keys = name.match(/[^[\]]+(?=])/g);

            //this is not necessary for some theme like flatsome
            if( $t.hasClass('plus') ){
                value ++;
            }

            if( $t.hasClass('minus') ){
                value --;
            }
            //end

            var request_info = 'context=frontend&action=yith_ywraq_action&ywraq_action=update_item_quantity&quantity='+value+'&key='+item_keys[0];

        }else{
            var value = $t.val(),
                item_keys = name.match(/[^[\]]+(?=])/g),
                request_info = 'context=frontend&action=yith_ywraq_action&ywraq_action=update_item_quantity&quantity='+value+'&key='+item_keys[0];

        }

        request = $.ajax({
            type   : 'POST',
            url    : ywraq_frontend.ajaxurl.toString().replace( '%%endpoint%%', 'yith_ywraq_action' ),
            dataType: 'json',
            data   : request_info,
            success: function ( response ) {

                if( typeof response.total !== 'undefined' && ywraq_frontend.hide_price == 0 ){
                    total_inline.html( response.line_total );
                    if( totals.length ){
                        totals.html( response.total );
                    }
                }
            }
        });
	} );
})(jQuery, window, document);
